import React, { useEffect, useState } from "react"
import { Seo, Layout, Header } from '../components'
import { CogIcon, CodeIcon, PresentationChartLineIcon } from '@heroicons/react/solid'
import { StaticImage } from "gatsby-plugin-image"
import LogoWordPress from '../assets/LogoWordPress.svg'
import LogoPrestashop from '../assets/LogoPrestashop.svg'
import LogoWooCommerce from '../assets/LogoWoocommerce.svg'
import LogoShopify from '../assets/LogoShopify.svg'
import LogoLaravel from '../assets/LogoLaravel.svg'
import LogoReact from '../assets/LogoReact.svg'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Confetti from 'react-dom-confetti';
import axios from "axios";
import Notification from "../components/Notification.js"
import Bouton from "../components/Bouton.js"
import Recaptcha from 'react-recaptcha'
import { PopupButton } from "react-calendly";
import { Switch } from '@headlessui/react'


const config = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "626px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

const IndexPage = ({ location }) => {

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
  const params = new URLSearchParams(location.search);
  const source = params.get("source");

  useEffect(() => {
    if (source === "qr") {
      setIsLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isLoaded) {
      setIsPageLoaded(true);
    }
  }, [isLoaded])


  const [agreed, setAgreed] = useState(false)

  const [list, setList] = useState([]);

  const [token, setToken] = useState();

  const [serverState, setServerState] = useState({
    submitting: "hidden",
    submittingPulse: "",
    status: null
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: "hidden",
      submittingPulse: "",
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };

  const validate = (form) => {
    let valid = true;
    let erreurs = [];
    if (!token) {
      erreurs.push("Veuillez valider le captcha");
      valid = false;
    }
    if (!form.nom.value.trim()) {
      erreurs.push("Merci de renseigner votre nom.");
      valid = false;
    }
    var nom = form.nom.value.trim();
    if (nom.length < 2) {
      erreurs.push("Votre nom doit contenir 2 caractères au minimum.");
      valid = false;
    }
    if (!form.prenom.value.trim()) {
      erreurs.push("Merci de renseigner votre prénom.");
      valid = false;
    }
    var prenom = form.prenom.value.trim();
    if (prenom.length < 2) {
      erreurs.push("Votre prénom doit contenir 2 caractères au minimum.");
      valid = false;
    }
    if (!form.email.value.trim()) {
      erreurs.push("Merci de renseigner votre email.");
      valid = false;
    }
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!form.email.value.match(regex)) {
      erreurs.push("Merci de renseigner un email correct.");
      valid = false;
    }
    var regexPhone = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;
    if (!form.numero.value.match(regexPhone)) {
      erreurs.push("Merci de renseigner un numéro de téléphone correct.");
      valid = false;
    }
    if (!form.message.value.trim()) {
      erreurs.push("Merci de renseigner votre message.");
      valid = false;
    }
    var message = form.message.value.trim();
    if (message.length < 25) {
      erreurs.push("Merci de renseigner un message de 25 caractères minimum.");
      valid = false;
    }
    if (!agreed) {
      erreurs.push("Vous devez accepter notre Politique de Confidientialité.");
      valid = false;
    }
    return {
      valid,
      erreurs
    }
  };


  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    let { valid, erreurs } = validate(form);
    if (valid) {
      setServerState({ submitting: "block", submittingPulse: "animate-pulse", });
      axios({
        method: "post",
        url: "https://www.koomet.fr/contact",
        data: {
          nom: form.nom.value,
          prenom: form.prenom.value,
          email: form.email.value,
          numero: form.numero.value,
          message: form.message.value,
          entreprise: form.entreprise.value,
          recaptcha: token,
        }
      })
        .then(r => {
          handleServerResponse(true, "Merci!", form);
          showToast("success");
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    } else {
      showToast("danger", erreurs);
    }
  };

  const showToast = (type, erreurs) => {
    let toastProperties = null;
    const id = Math.floor((Math.random() * 100) + 1);
    switch (type) {
      case 'success':
        toastProperties = {
          id,
          title: 'Message envoyé',
          description: 'Votre message a bien été envoyé !',
          icon: 'check',
        }
        break;
      case 'danger':
        toastProperties = {
          id,
          title: 'Erreur',
          icon: 'error',
          description: erreurs.map((erreur, i) => {
            return <span key={i} className="whitespace-pre-wrap">{erreur + "\n"}</span>

          }),
        }
        break;
      default:
        setList([]);
    }
    setList([...list, toastProperties]);
  }

  return (
    <Layout>

      <Seo 
      
      title="Koomet - Agence web à Perpignan spécialiste de la création de site internet" 
      desc="Notre agence web à Perpignan vous accompagne dans la création de votre site Internet sur-mesure, son référencement naturel (SEO) et sa maintenance !"
      />
      <div className="fixed top-52 left-0 z-50">
        <Confetti active={
          isLoaded
        } config={config} />
      </div>
      <div className="fixed top-52 right-0 z-50">
        <Confetti active={
          isLoaded
        } config={config} />
      </div>

      <div className="container mx-auto px-6">
        <div>
      {isLoaded ? <div className="relative pb-2 sm:pb-5">
      <div className="w-full mx-auto">
        <div className="p-2 rounded-lg bg-w-primary shadow-lg sm:p-3">
          <div className="flex items-center justify-between flex-wrap">
            <div className="flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-violetDarken/20">
                <CogIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="relative ml-3 font-medium text-white">
                <span className="inline">Merci d'avoir scanné notre QR Code ! Votre nouvelle agence web en ligne à Saint-Amand-Montrond est ouverte ! Suivez-nous dès maintenant sur Facebook.</span>
              </p>
            </div>
            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <a href="https://www.facebook.com/koometagency"
                target="_blank" rel="noreferrer"
                className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
              >
                Notre Facebook
              </a>
            </div>
          </div>
        </div>
      </div>
    </div> : ''}
    </div>
        <Header />
        <Notification toastList={list} />
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
          <AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/creation-site-internet/" data-sal-delay="100" data-sal="zoom-in"
            data-sal-duration="1000" data-sal-easing="ease-in-out-quad" className="sm:col-span-2 lg:col-span-1 flex flex-col group p-10 rounded-xl transition shadow-green-400/5 dark:from-green-500/5 dark:to-green-400/30 bg-gradient-to-br from-green-50 to-white shadow-xl hover:bg-opacity-90 active:bg-opacity-100 focus:outline-none focus:ring-4 focus:ring-green-200 focus:ring-opacity-40">
            <h2 className="flex-none inline-flex items-center space-x-2 text-2xl font-semibold text-green-400 mb-2">
              <CodeIcon className="inline-block w-8 h-8 opacity-50" />
              <span>Création de site Internet</span>
            </h2>
            <p className="flex-grow text-gray-600 dark:text-white opacity-90 leading-relaxed text-sm mb-10">
              Renforcez votre visibilité en ligne, confiez-nous la création de site web pour votre activité. (Site vitrine, site e-commerce, Click & Collect, etc.)
            </p>
            <div className="flex-none -mb-10 opacity-50 group-hover:opacity-100 transition duration-700 ease-in-out transform group-hover:scale-110 group-hover:shadow-lg">
              <StaticImage
                src="../assets/SiteWeb.png"
                quality={100}
                alt="Création de site internet vitrine et site e-commerce"
                className="group-hover:shadow-lg group-hover:shadow-green-400/5 rounded-tl-md rounded-tr-md group-hover:rounded-md transition-all duration-700 ease-in-out"
              />
            </div>
          </AniLink>
          <AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/referencement-naturel-site-internet/" data-sal-delay="200" data-sal="zoom-in"
            data-sal-duration="1000" data-sal-easing="ease-in-out-quad" className="flex flex-col group p-10 rounded-xl transition shadow-blue-400/5 dark:from-blue-500/5 dark:to-blue-400/30 bg-gradient-to-br from-blue-50  to-white shadow-xl hover:bg-opacity-90 active:bg-opacity-100 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:ring-opacity-40">
            <h2 className="flex-none inline-flex items-center space-x-2 text-2xl font-semibold text-blue-400 mb-2">
              <PresentationChartLineIcon className="inline-block w-5 h-5 opacity-50" />
              <span>Référencement naturel (SEO)</span>
            </h2>
            <p className="flex-grow text-gray-600 dark:text-white opacity-90 leading-relaxed text-sm mb-10">
              Améliorez le référencement de votre site Internet et optimisez la visibilité de votre site Internet sur les moteurs de recherche (Google, Bing...) grâce à notre expertise SEO.
            </p>
            <div className="flex-none -mb-10 opacity-50 group-hover:opacity-100 transition duration-700 ease-in-out transform group-hover:scale-110">
              <StaticImage
                src="../assets/SEO.png"
                quality={100}
                alt="Référencement naturel SEO sur Google et autres moteurs de recherche."
                className="group-hover:shadow-lg group-hover:shadow-blue-400/5 rounded-tl-md rounded-tr-md group-hover:rounded-md transition-all duration-700 ease-in-out"
              />
            </div>
          </AniLink>
          <AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/maintenance-site-internet/" data-sal-delay="300" data-sal="zoom-in"
            data-sal-duration="1000" data-sal-easing="ease-in-out-quad" className="flex flex-col group p-10 rounded-xl transition shadow-red-400/5  dark:from-red-500/5 dark:to-red-400/30 bg-gradient-to-br from-red-50 to-white shadow-xl hover:bg-opacity-90 active:bg-opacity-100 focus:outline-none focus:ring-4 focus:ring-red-200 focus:ring-opacity-40">
            <h2 className="flex-none inline-flex items-center space-x-2 text-2xl font-semibold text-red-400 mb-2">
              <CogIcon className="inline-block w-5 h-5 opacity-50" />
              <span>Maintenance de site Internet</span>
            </h2>
            <p className="flex-grow text-gray-600 dark:text-white opacity-90 leading-relaxed text-sm mb-10">
              Assurez le bon fonctionnement de votre site Internet, confiez sa maintenance à notre agence web (ajout de contenu, sauvegardes, mise à jour de contenu, etc.).
            </p>
            <div className="flex-none -mb-10 opacity-50 group-hover:opacity-100 transition duration-700 ease-in-out transform group-hover:scale-110">
              <StaticImage
                src="../assets/Maintenance.png"
                quality={100}
                alt="Maintenance de site Internet WordPress, PrestaShop et autres CMS"
                className="group-hover:shadow-lg group-hover:shadow-red-400/5 rounded-tl-md rounded-tr-md group-hover:rounded-md transition-all duration-700 ease-in-out"
              />
            </div>
          </AniLink>
        </div>
        <div className="max-w-7xl mx-auto py-12 lg:py-16">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div data-sal="slide-right"
              data-sal-duration="1000" data-sal-easing="ease-in-out-quad">
              <h3 className="relative text-3xl font-extrabold text-violetDarken dark:text-white sm:text-4xl">
                Des technologies <span className="bg-gradient-to-tr px-3 inline-block from-indigo-400 to-indigo-500 text-white">adaptées à vos besoins.</span>
              </h3>
              <p className="mt-3 max-w-3xl text-lg text-gray-500 dark:text-gray-200">
                Grâce à un large panel de compétences & de connaissances, nous vous proposons des solutions adaptées à vos besoins.
              </p>
              <p className="mt-3 max-w-3xl text-lg text-gray-500 dark:text-gray-200">
                De la création de votre site internet vitrine ou e-commerce à la maintenance de votre site internet, nous vous accompagnons dans la réalisation de votre projet.
              </p>
              <div className="mt-8 sm:flex">
                <div className="rounded-md shadow">
                  <AniLink
                    cover bg="#6377E9" direction="up" duration={0.6} to="/creation-site-internet/"
                    href="#"
                    className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-w-primary hover:bg-w-primary/90 transition ease-in-out duration-300"
                  >
                    En savoir plus...
                  </AniLink>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <AniLink
                    cover bg="#6377E9" direction="up" duration={0.6} to="/contact/"
                    href="#"
                    className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-violet bg-w-primary/20 hover:bg-w-primary/30 dark:text-white transition ease-in-out duration-300"
                  >
                    Nous contacter
                  </AniLink>
                </div>
              </div>
            </div>
            <div className="mt-8 grid grid-cols-2 gap-1 md:grid-cols-3 lg:mt-0 lg:grid-cols-2 text-gray-400 dark:text-violet" data-sal="slide-left"
              data-sal-duration="1000" data-sal-easing="ease-in-out-quad">
              <div className="col-span-1 group hover:bg-violetBright transition ease-in duration-300 flex justify-center py-8 px-8 rounded-md bg-gray-50 dark:bg-d-primary/40 dark:hover:bg-violetBright">
                <LogoWordPress className="max-h-12 group-hover:text-white transition ease-in duration-300" />
              </div>
              <div className="col-span-1 group hover:bg-violetBright transition ease-in duration-300 flex justify-center py-8 px-8 rounded-md bg-gray-50 dark:bg-d-primary/40 dark:hover:bg-violetBright">
                <LogoWooCommerce className="max-h-12 group-hover:text-white transition ease-in duration-300" />
              </div>
              <div className="col-span-1 group hover:bg-violetBright transition ease-in duration-300 flex justify-center py-8 px-8 rounded-md bg-gray-50 dark:bg-d-primary/40 dark:hover:bg-violetBright">
                <LogoPrestashop className="max-h-12 group-hover:text-white transition ease-in duration-300" />
              </div>
              <div className="col-span-1 group hover:bg-violetBright transition ease-in duration-300 flex justify-center py-8 px-8 rounded-md bg-gray-50 dark:bg-d-primary/40 dark:hover:bg-violetBright">
                <LogoShopify className="max-h-12 group-hover:text-white transition ease-in duration-300" />
              </div>
              <div className="col-span-1 group hover:bg-violetBright transition ease-in duration-300 flex justify-center py-8 px-8 rounded-md bg-gray-50 dark:bg-d-primary/40 dark:hover:bg-violetBright">
                <LogoLaravel className="max-h-12 group-hover:text-white transition ease-in duration-300" />
              </div>
              <div className="col-span-1 group hover:bg-violetBright transition ease-in duration-300 flex justify-center py-8 px-8 rounded-md bg-gray-50 dark:bg-d-primary/40 dark:hover:bg-violetBright">
                <LogoReact className="max-h-12 group-hover:text-white transition ease-in duration-300" />
              </div>
            </div>
          </div>
        </div>


      </div>

      <div className="mx-auto overflow-hidden">
            <div className="relative bg-w-secondary dark:bg-d-secondary/95 rounded-large p-10 mx-auto">
              <div className="text-center">
                <h2 className="font-bold tracking-tight dark:text-white text-gris text-3xl">Contactez notre agence web !</h2>
                <p className="text-gray-500 dark:text-gray-200 max-w-3xl mx-auto mt-3">Koomet est une agence web basée à Perpignan accompagnant les TPE & PME (très petites et moyennes entreprises) dans la création de leur site internet, la maintenance et le référencement de leur site.</p>
              </div>
              <div className="mt-12 max-w-2xl mx-auto">
                <form onSubmit={handleOnSubmit} className={serverState.submittingPulse + " grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"}>
                  <div>
                    <label htmlFor="nom" className="block text-sm font-medium text-gris dark:text-white">
                      Nom*
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        type="text"
                        name="nom"
                        id="nom"
                        autoComplete="given-name"
                        className="py-3 px-4 block w-full dark:bg-d-secondary focus:outline-none shadow-sm dark:border-violet focus:ring-violet focus:border-violet border border-gris rounded-md"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="prenom" className="block text-sm font-medium text-gris dark:text-white">
                      Prénom*
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        type="text"
                        name="prenom"
                        id="prenom"
                        autoComplete="family-name"
                        className="py-3 px-4 block w-full dark:bg-d-secondary shadow-sm focus:outline-none dark:border-violet focus:ring-violet focus:border-violet border-gris border rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="entreprise" className="block text-sm font-medium text-gris dark:text-white">
                      Entreprise
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="entreprise"
                        id="entreprise"
                        autoComplete="organization"
                        className="py-3 px-4 block w-full dark:bg-d-secondary shadow-sm focus:outline-none dark:border-violet focus:ring-violet focus:border-violet border-gris border rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="email" className="block text-sm font-medium text-gris dark:text-white required">
                      Adresse email*
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        placeholder="elon@tesla.com"
                        className="py-3 px-4 block w-full dark:bg-d-secondary shadow-sm focus:outline-none dark:border-violet focus:ring-violet focus:border-violet border border-gris rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="numero" className="block text-sm font-medium text-gris dark:text-white">
                      Numéro de téléphone
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="text"
                        name="numero"
                        id="numero"
                        autoComplete="tel"
                        className="py-3 px-4 block w-full dark:bg-d-secondary focus:outline-none dark:border-violet focus:border-violet focus:ring-violet border border-gris rounded-md"
                        placeholder="06 18 96 16 80"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="message" className="block text-sm font-medium text-gris dark:text-white">
                      Message*
                    </label>
                    <div className="mt-1">
                      <textarea
                        required
                        id="message"
                        name="message"
                        rows={4}
                        className="py-3 px-4 block w-full dark:bg-d-secondary focus:outline-none shadow-sm dark:border-violet focus:ring-violet focus:border-violet border border-gris rounded-md"
                        defaultValue={''}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        <Switch
                          checked={agreed}
                          onChange={setAgreed}
                          className={classNames(
                            agreed ? 'bg-w-primary' : 'bg-gray-300',
                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
                          )}
                        >
                          <span className="sr-only">Accepter notre Politique de Confidentialité</span>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              agreed ? 'translate-x-5' : 'translate-x-0',
                              'inline-block h-5 w-5 rounded-full bg-w-secondary shadow transform ring-0 transition ease-in-out duration-200'
                            )}
                          />
                        </Switch>
                      </div>
                      <div className="ml-3">
                        <p className="text-base text-gris dark:text-white">
                          En sélectionnant ceci, vous acceptez notre{' '}
                          <AniLink className="font-medium text-gris dark:text-white underline" cover bg="#6377E9" direction="up" duration={0.6} to="/politique-de-confidentialite/">
                            Politique de Confidentialité
                          </AniLink>
                          .
                        </p>
                      </div>
                    </div>
                    <Recaptcha verifyCallback={token => { setToken(token) }} required hl="fr_FR" className="mt-3 w-full inline-flex items-center justify-center lg:items-start lg:justify-start" sitekey="6LdKWtUdAAAAAOgIZAfQsOpsmekLheMe_NxqKELP" render="explicit" />
                  </div>
                  <div className="sm:col-span-2">
                    <Bouton modele="submit" className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-w-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gris">

                      <svg className={serverState.submitting + " animate-spin -ml-1 mr-3 h-5 w-5 text-white"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Envoyer ma demande
                    </Bouton>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="relative bg-gradient-to-r from-indigo-400 via-violet to-indigo-400">
        <div className="mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            <span className="block">Vous souhaitez être rappelé par notre agence web ?</span>
          </h2>

          <PopupButton
                        className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-violet bg-white hover:bg-indigo-50 sm:w-auto transition duration-200 hover:-translate-y-0.5 hover:shadow-md"
                        pageSettings={{
                          backgroundColor: 'ffffff',
                          hideEventTypeDetails: false,
                          hideGdprBanner: false,
                          hideLandingPageDetails: false,
                          primaryColor: '6377E9',
                          textColor: '6377E9'
                        }}
                        styles={{}}
                        text="Me faire rappeler 📱"
                        url="https://calendly.com/koomet"
                      />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage